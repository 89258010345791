import { defineStore } from 'pinia';

interface ContentfulGqlStore {
  cache: {
    [key: string]: [{
      response: object,
      variables: string
    }]
  },
}

export const useContentfulGqlStore = defineStore('contentfulGql', {
  state: (): ContentfulGqlStore => ({
    cache: {}
  }),
  getters: {
    getCachedItem: state => (queryName: string, variables: string): object => {
      return state.cache[queryName]?.find(item => item.variables === variables)?.response
    },
  }
})

