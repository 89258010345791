module.exports = {
  getCurrentContentfulLocale: (context) => {
    return context.i18n.localeProperties.contentfulLocale;
  },
  setContentfulLocaleIfEmpty: (variables, context) => {
    if(!variables.locale) {
      variables.locale = context.i18n.localeProperties.contentfulLocale;
    }
    return variables;
  }
}
